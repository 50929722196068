
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import StandardFlag from "@/components/scope/standard-flag.vue";
import { Pageniation, TableRowsSelect, Table } from "@/utils/decorator";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { splitThousands } from "@/utils/common";
const management = namespace("management");
const base = namespace("base");
import SwitchStatus from "@/components/scope/switch-status.vue";
import t from "@common/src/i18n/t";
import message from "@/extends/message";
import serviceItem from "@/store/modules/service-item";
const service = namespace("service-item");
const app = namespace("app");
@Component({
  components: { TableEnableFlag, StandardFlag, SwitchStatus }
})
@Pageniation("loadListData")
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.choose-payment-method"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class Service extends Vue {
  @service.Action queryTwoLevelClassList;
  @service.Action getServiceItemList;
  @service.Action updateServiceItemStatus;
  @service.Action getAssignStore;
  @service.Action saveAssignStore;
  @service.Action setServiceSort;
  @service.Action batchAssignStore;
  @service.Action batchCancelAssignStore;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @base.Action getDictionaryList;
  @management.Action getStoreList;


  searchFrom = {
    supplierName: "",
    contact: "",
    contactMobile: ""
  };
  selectSearchFrom = {
    serviceCategoryCode: "",
    status: ""
  };
  keyword: string = "";
  serviceList: any[] = [];
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  handleSearch() {
    this.mix_pageNum = 1;
    this.loadListData();
  }
  serviceCategoryList = [];
  created() {
    this.loadListData();
    // 获取服务分类列表
    this.queryTwoLevelClassList({
      pageNum: 1,
      pageSize: 999
    }).then(res => {
      this.serviceCategoryList = res.data.list;
    });
    this.getDictionaryList("store_type").then(data => {
      console.log("[ storeTypeList ]", data);
      this.storeTypeList = data;
    });
  }
  @Pageniation.LoadDataMethod()
  loadListData() {
    return this.getServiceItemList({
      orderItems: this.mix_sortParams,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum,
      searchKey: this.keyword,
      ...this.selectSearchFrom,
      status: this.selectSearchFrom.status === "" ? "9" : this.selectSearchFrom.status
    }).then(data => {
      this.serviceList = data.data.list;
      return data;
    });
  }
  handleEdit(row) {
    this.$router.push(`/management/service-edit/${row.serviceCode}`);
  }
  confirmUpdateStatus(status, selectedRow?) {
    return this.updateServiceItemStatus({
      status: status,
      serviceCodeList: [selectedRow.serviceCode]
    })
      .then(data => {
        this.$message.success(
          status === 1
            ? (this.$t("setting.enable-successful") as string)
            : (this.$t("setting.disable-success") as string)
        );
        this.loadListData();
      })
      .catch(e => {
        if (e.code === "speedbiz.system.pay-method-least-one-valid") {
          this.$message.error(t("setting.valid-method-payment"));
        }
      });
  }

  multipleSelection = {
    size: 0
  };
  batchOpVisible: boolean = false;
  batchOpAssignType: number = 0; //1-分配 2取消分配

  handlerBatchOpOpen(){
    this.batchOpVisible = true;
    (this.$refs['serviceItemTable'] as any).clearSelection();
  }
  handlerBatchOpClose(){
    this.batchOpVisible = false;
    (this.$refs['serviceItemTable'] as any).clearSelection();
  }



  handleBathAssign(type){
    const selectedRows = (this.$refs['serviceItemTable'] as any).selection;
    if (selectedRows.length < 1) {
      this.$message.error("请选择服务项");
      return;
    }
    this.batchOpAssignType = type;
    this.storeDialogVisible=true;
    console.log("this.$refs['storeTable']");
    console.log(this.$refs['storeTable']);
    (this.$refs['storeTable'] as any).init();
  }

  handleSelectionChange(val) {
    this.multipleSelection.size = val.length;

    console.log('multipleSelectionSize', this.multipleSelection);
  }

  handleSaveBathAssign(){
    const selectedRows = (this.$refs['serviceItemTable'] as any).selection;
    if (selectedRows.length < 1) {
      this.$message.error("请选择服务项");
      return;
    }
    if (this.selectionStoreRowList.length < 1) {
      this.$message.error("请选择门店");
      return;
    }

    const serviceItemCode = [];
    selectedRows.forEach(serviceItem => {
      serviceItemCode.push(serviceItem.serviceCode);
    });
    const storeCodes = [];
    this.selectionStoreRowList.forEach(storeItem => {
      storeCodes.push(storeItem.storeCode);
    });

    if (this.batchOpAssignType == 1) {
      this.bathAssign(serviceItemCode, storeCodes);
    } else if (this.batchOpAssignType == 2) {
      this.bathCancelAssign(serviceItemCode, storeCodes);
    }
  }

  bathAssign(serviceItemCodes, storeCodes){
    this.btnStartLoading();
    this.batchAssignStore({
      serviceCodes: serviceItemCodes,
      storeCodes: storeCodes
    })
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.storeDialogVisible = false;
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }

  bathCancelAssign(serviceItemCodes, storeCodes){
    this.btnStartLoading();
    this.batchCancelAssignStore({
      serviceCodes: serviceItemCodes,
      storeCodes: storeCodes
    })
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.storeDialogVisible = false;
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }

  storeSearchForm = {
    storeName: "",
    storeType: "",
    storeTypeList: [3, 7, 8, 9]
  };

  storeDialogVisible: boolean=false;

  storeTypeList: any = [];

  storelistLoading: boolean = false;
  storeList: any=[];
  storePageHandler(params, num, type) {
    if(type == 'reset'){
      this.resetSearchData();
    }
    this.selectStoreList(params, num ? num : params.pageNum, params.pageSize);
  }


  resetSearchData() {
    this.storeSearchForm = {
      storeName: "",
      storeType: "",
      storeTypeList: [3, 7, 8, 9]
    }
  }

  // 获取门店列表
  selectStoreList(params,pageNum,pageSize) {
    this.storelistLoading = true;
    this.getStoreList({
      ...this.storeSearchForm,
      pageNum: pageNum,
      pageSize: pageSize
    }).then(data => {
      console.log("storeList", data);
      this.storeList = data.data.list;
      params.render(data.data.total);
      this.storelistLoading = false;
      return data;
    });
  }

  selectionStoreRowList: any=[];
  storeTableSelectionChange(val){
    this.selectionStoreRowList = val;
  }









  assignDialogVisible=false;
  assignServiceCode='';
  serviceAssignStoreData=[];
  selectServiceAssignStoreData = []
  handleAssign(row) {
    this.assignServiceCode = row.serviceCode
    this.serviceAssignStoreData = [];
    this.selectServiceAssignStoreData = [];
    this.getAssignStore({
      "serviceCode": this.assignServiceCode
    }).then(data => {

      console.log("server data");
      console.log(data.data);

      let allStore = data.data.assignStores.concat(data.data.noAssignStores);
      allStore.forEach(item => {
        const obj = {"key": item.storeCode, "label": item.storeName}
        this.serviceAssignStoreData.push(obj);
      });

      console.log("serviceAssignStoreData");
      console.log(this.serviceAssignStoreData);

      data.data.assignStores.forEach(item => {
        this.selectServiceAssignStoreData.push(item.storeCode);
      });

      console.log("selectServiceAssignStoreData");
      console.log(this.selectServiceAssignStoreData);
    });
    this.assignDialogVisible = true;
  }

  handleSaveAssign() {
    this.btnStartLoading();
    this.saveAssignStore({
      serviceCode: this.assignServiceCode,
      storeCodes: this.selectServiceAssignStoreData
    })
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.assignDialogVisible = false;
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }

  // 列表排序按钮
  handleSort(row, sort) {
    console.log(row, sort)
    this.setServiceSort({
      serviceCode: row.serviceCode,
      sortOpt: sort
    }).then(res => {
      console.log(res);
      if (res.code == 0) {
        this.loadListData();
      } else {
        this.$message.error("操作失败");
      }
    });
  }

  // 启用状态，服务分类选择查询
  searchListFn(e) {
    this.mix_pageSize = 10;
    this.mix_pageNum = 1;
    this.loadListData();
  }
}
